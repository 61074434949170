import _ from 'lodash';

export const wrapTryCatch = onCatch => fn => {
  //tslint:disable-next-line:only-arrow-functions
  return function() {
    try {
      return fn.apply(null, arguments);
    } catch (e) {
      onCatch && onCatch(e);
    }
  };
};

export const wrapExports = onCatch => o => {
  const wrapper = wrapTryCatch(onCatch);
  return _.reduce(
    o,
    (result, value, key) => ({
      [key]: _.isFunction(value) ? wrapper(value) : value,
      ...result,
    }),
    {}
  );
};
