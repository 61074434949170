import {SiteStore} from '@wix/wixstores-client-core/dist/src/viewer-script/siteStore';

export class PubSubManager {
  private readonly subscribers: {[s: string]: number[]} = {};

  constructor(private readonly pubSub: SiteStore['pubSub']) {
    //
  }

  subscribe(event: string, fn: Function, persistent: boolean = false): number {
    const id = this.pubSub.subscribe(event, fn, persistent);
    if (this.subscribers[event]) {
      this.subscribers[event].push(id);
    } else {
      this.subscribers[event] = [id];
    }
    return id;
  }

  publish(event: string, data: any, persistent: boolean = false) {
    this.pubSub.publish(event, data, persistent);
  }

  unsubscribe(event: string, id: number) {
    this.subscribers[event] = this.subscribers[event].filter(s => s !== id);
    this.pubSub.unsubscribe(event, id);
  }

  unsubscribeAll() {
    Object.keys(this.subscribers).forEach(event => {
      this.subscribers[event].forEach(s => {
        this.pubSub.unsubscribe(event, s);
      });
    });
  }
}
