import {IProductCustomTextAnswer, IProductCustomTextViewAnswer} from '../types/product';
import {
  IOptionsSelections,
  IOptionsSelectionsByNames,
  IProductCartWithNamedOptionsRequest,
  IProductCartRequest,
  IDataResponse,
} from '../types/cart';
import CommandsExecutor from '@wix/wixstores-client-core/dist/src/commands-executor/commands-executor';
import {CART_ID} from './cartStore';
import {SECTION_ID, specs, GRAPHQL_URL} from '../constants';
import {SiteStore} from '@wix/wixstores-client-core/dist/src/viewer-script/siteStore';
import {PubSubManager} from './PubSubManager';
import Experiments from '@wix/wix-experiments';
import {GetDataQuery} from '../graphql/queries-schema';

const CART_COMMANDS_URL = '/_api/wix-ecommerce-renderer-web/store-front/cart/{commandName}';

export class CartActions {
  private readonly commandsExecutor;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly pubSubManager: PubSubManager,
    private readonly experiments: Experiments,
    private readonly origin: string
  ) {
    this.commandsExecutor = new CommandsExecutor(CART_COMMANDS_URL, this.siteStore.httpClient);
  }

  addToCart(
    productId: string,
    optionsSelections: IOptionsSelections | IOptionsSelectionsByNames,
    quantity: number,
    cartId: string,
    customTextFields: IProductCustomTextAnswer[] = []
  ): Promise<void> {
    return this.sendCommandAddToCart(productId, optionsSelections, quantity, cartId, customTextFields).then(
      ({cartSummary}) => {
        this.saveCart(cartSummary);
        this.siteStore.isMobile() && this.navigateToCart(this.origin);
      }
    );
  }

  getCartWithId(cartId: string) {
    return this.siteStore.httpClient.get(`/_api/wix-ecommerce-renderer-web/store-front/get-cart/cart/${cartId}`);
  }

  getData(externalId: string = '') {
    const data: any = {
      query: require('!raw-loader!../graphql/getData.graphql'),
      source: 'WixStoresWebClient',
      operationName: 'getCartService',
      variables: {externalId: externalId || ''},
    };

    return this.siteStore.httpClient.post(this.siteStore.resolveAbsoluteUrl(`/${GRAPHQL_URL}`), data) as Promise<{
      data: GetDataQuery;
    }>;
  }

  private sendCommandAddToCart(
    productId: string,
    optionsSelections: IOptionsSelections | IOptionsSelectionsByNames,
    quantity: number,
    cartId: string,
    customTextFields: IProductCustomTextAnswer[] = []
  ): Promise<IDataResponse> {
    const postData = {
      productId,
      quantity,
    } as IProductCartRequest | IProductCartWithNamedOptionsRequest;

    if (Array.isArray(optionsSelections)) {
      (postData as IProductCartRequest).optionsSelections = optionsSelections;
    } else {
      (postData as IProductCartWithNamedOptionsRequest).optionsSelectionsByNames = optionsSelections;
    }

    postData.freeText = customTextFields.map(customTextFieldAnswer => {
      return {
        title: customTextFieldAnswer.customText.title,
        value: customTextFieldAnswer.answer,
      } as IProductCustomTextViewAnswer;
    });

    if (!this.experiments.enabled(specs.stores.OutOfIFrameRemoveCartId) && cartId) {
      postData.cartId = cartId;
    }
    return this.commandsExecutor.execute('AddCartItemCommand', postData);
  }

  private saveCart(cartSummary: IDataResponse['cartSummary'], shouldOpenCart: boolean = true) {
    cartSummary.extraParams = {origin: this.origin};
    cartSummary.eventOptions = {shouldOpenCart};
    if (this.siteStore.isLocalStorageSupport) {
      localStorage.setItem(`${CART_ID}${this.siteStore.storeId}`, cartSummary.cartId);
    } else {
      this.pubSubManager.publish('Cart.SharedCartData', cartSummary);
    }
    this.pubSubManager.publish('Cart.Changed', cartSummary);
  }

  public navigateToCart(origin: string) {
    this.siteStore.location.navigateToSection &&
      this.siteStore.location.navigateToSection({
        sectionId: SECTION_ID,
        queryParams: {origin},
      });
  }
}
