export const POPUP_URL = 'https://ecom.wix.com/storefront/cartwidgetPopup';
export const SECTION_ID = 'shopping_cart';
export const CART_ICON_DSN = 'https://31e500c94d2042ff81a38a0e4a2873b7@sentry.io/1252955';
export const EMPTY_CART_GUID = '00000000-000000-000000-000000000000';
export const GRAPHQL_URL = '_api/wix-ecommerce-graphql-web/api';
export const STOREFRONT_GRAPHQL_URL = '_api/wix-ecommerce-storefront-web/api';
export const specs = {
  stores: {
    OutOfIFrameRemoveCartId: 'specs.stores.OutOfIFrameRemoveCartId',
  },
};
export const SENTRY_DSN = 'https://31e500c94d2042ff81a38a0e4a2873b7@sentry.io/1252955';

export const cartIconTranslationPath = (baseUrl, locale) => `${baseUrl}assets/locale/cartIcon/cartIcon_${locale}.json`;

export const CART_ICON_WIDGET_ID = '1380bbc4-1485-9d44-4616-92e36b1ead6b';
export const PRODUCT_WIDGET_ID = '13a94f09-2766-3c40-4a32-8edb5acdd8bc';
