import {cartIconTranslationPath, POPUP_URL, SECTION_ID, specs, EMPTY_CART_GUID} from '../constants';
import {ProductType} from '@wix/wixstores-client-core/dist/src/types/product';
import {CartType} from '@wix/wixstores-client-core/dist/src/types/cart';
import {IAddToCartOptions, IProductCustomTextAnswer} from '../types/product';
import {CartActions} from './cartActions';
import {ICartItem, IDataResponse} from '../types/cart';
import {create} from '@wix/fedops-logger';
import _ from 'lodash';
import {getTranslations, translate} from '@wix/wixstores-client-core/dist/src/viewer-script/utils';
import {SiteStore} from '@wix/wixstores-client-core/dist/src/viewer-script/siteStore';
import {PubSubManager} from './PubSubManager';
import {newCartToOldCartStructure} from '@wix/wixstores-client-core/dist/src/common/cart-services/cart-services';
import Experiments from '@wix/wix-experiments';
import {ICartIconControllerConfig, ICtrlProps} from '../types/app-types';
import {getLocaleNamespace} from '@wix/wixstores-multilingual-adapter/dist/src/locale-namespace';

export const CART_ID = 'cartId_';

export class CartStore {
  private translations = {};
  private cart;
  private cartIdPromise: Promise<string>;
  private readonly cartActions: CartActions;
  private readonly fedopsLogger;
  private cartPromise: Promise<any>;
  private readonly pubSubManager: PubSubManager;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly config: ICartIconControllerConfig,
    private readonly compId: string,
    private readonly experiments: Experiments,
    private readonly updateComponent: Function,
    private readonly reportError: (e) => any
  ) {
    this.pubSubManager = new PubSubManager(this.siteStore.pubSub);
    this.cartActions = new CartActions(this.siteStore, this.pubSubManager, this.experiments, 'wixcode');
    if (!this.siteStore.isEditorMode()) {
      this.registerEvents();
      this.registerWixCode();
    } else {
      window.onunload = () => this.pubSubManager.publish('Minicart.isExist', false, true);
    }
    this.fedopsLogger = create('wixstores-cart-icon');
  }

  registerWixCode() {
    this.pubSubManager.subscribe(`Wixcode.AddToCart_${this.compId}`, ({data}) =>
      this.addToCart(data.productId, data.quantity, data.options)
        .then(() => this.pubSubManager.publish(`Wixcode.AddToCart.Added_${this.compId}`, {isResolve: true}))
        .catch(() => this.pubSubManager.publish(`Wixcode.AddToCart.Added_${this.compId}`, {isResolve: false}))
    );
  }

  updateCart(cart) {
    this.cart = cart;
    const count = this.getTotalQuantity(this.cart.items);
    this.updateComponent({
      ...this.getCountRelatedProps(count),
    });
  }

  registerEvents() {
    this.pubSubManager.publish('Minicart.isExist', true, true);
    this.pubSubManager.subscribe('Cart.Cleared', () => {
      this.updateCart({items: []});
    });

    this.pubSubManager.subscribe('Cart.Changed', res => {
      this.updateCart(res.data);
    });

    if (!this.siteStore.isMobile()) {
      setTimeout(this.initPopup, this.siteStore.isSiteMode() ? 1500 : 0);
    }
  }

  private getCartId() {
    if (!this.cartIdPromise) {
      if (!this.siteStore.isLocalStorageSupport) {
        this.cartIdPromise = new Promise(resolve => {
          const sharedCartDataEventId = this.pubSubManager.subscribe(
            'Cart.SharedCartData',
            res => {
              this.pubSubManager.publish('Cart.Loaded', res.data);
              resolve(res.data.cartId);
              this.pubSubManager.unsubscribe('Cart.SharedCartData', sharedCartDataEventId);
            },
            true
          );

          _.delay(() => {
            resolve();
            this.pubSubManager.unsubscribe('Cart.SharedCartData', sharedCartDataEventId);
          }, 2000);
        });
      } else {
        this.cartIdPromise = Promise.resolve(localStorage.getItem(`${CART_ID}${this.siteStore.storeId}`));
      }
    }
    return this.cartIdPromise;
  }

  async setInitialState() {
    return Promise.all([
      this.getData(),
      getTranslations(cartIconTranslationPath(this.siteStore.baseUrls.cartIconBaseUrl, this.siteStore.locale)),
      this.siteStore.getSectionUrl(SECTION_ID),
    ])
      .then(([serverResponse, translationsResponse, cartLink]) => {
        this.cart = serverResponse.cartSummary;
        this.translations = translationsResponse;
        const count = this.getTotalQuantity(this.cart.items);

        const props = {
          ...this.getCountRelatedProps(count),
          cartLink: cartLink.url,
          isInteractive: this.siteStore.isInteractive(),
          isLoaded: true,
          displayText: this.getDisplayText(serverResponse.widgetSettings),
          triggerFocus: false,
          onFocusTriggered: this.onFocusTriggered,
          isNavigate: !this.isOpenPopup(),
          onIconClick: this.onIconClick,
          onAppLoaded: this.onAppLoaded,
          cssBaseUrl: this.siteStore.baseUrls.cartIconBaseUrl,
        } as ICtrlProps;
        this.updateComponent(props);
      })
      .catch(this.reportError);
  }

  onFocusTriggered = () => {
    this.updateComponent({
      triggerFocus: false,
    });
  };

  onAppLoaded = () => {
    this.fedopsLogger.appLoaded();
  };

  getDisplayText(widgetSettings) {
    const defaultValue = this.translations[`CART_ICON_${this.config.style.styleParams.numbers.cartWidgetIcon}`];
    let widgetSettingsForLocale = {};
    if (
      this.siteStore.getMultiLangFields() &&
      !this.siteStore.getMultiLangFields().isPrimaryLanguage &&
      widgetSettings
    ) {
      widgetSettingsForLocale = widgetSettings[getLocaleNamespace(this.siteStore.getMultiLangFields().lang)];
      return _.get(widgetSettingsForLocale, 'CART_ICON_TEXT', '') || defaultValue;
    }
    return _.get(this.config, 'publicData.APP.CART_ICON_TEXT', '') || defaultValue;
  }

  async getData(): Promise<IDataResponse> {
    if (this.cartPromise) {
      return this.cartPromise;
    }

    if (this.experiments.enabled(specs.stores.OutOfIFrameRemoveCartId)) {
      this.cartPromise = this.cartActions.getData(this.config.externalId).then(({data}) => {
        return {
          cartSummary: newCartToOldCartStructure(data.cartService.cart as any),
          widgetSettings: _.get(data, 'appSettings.widgetSettings', {}),
        };
      });
    } else {
      const cartId = await this.getCartId();
      if (!cartId) {
        return Promise.resolve({cartSummary: {items: []}, errors: [], widgetSettings: {}} as IDataResponse);
      }
      this.cartPromise = this.cartActions.getCartWithId(cartId).then(cartResponse => {
        if (cartResponse.errors.length) {
          return {cartSummary: {items: []}, errors: [], widgetSettings: {}};
        }
        return cartResponse;
      });
    }
    return this.cartPromise;
  }

  isOpenPopup() {
    return (
      (!this.config.style.styleParams.numbers.iconLink || this.config.style.styleParams.numbers.iconLink === 2) &&
      !this.siteStore.isMobile()
    );
  }

  openPopup = () => {
    let popupUrl = POPUP_URL;

    if (this.config.externalId) {
      popupUrl += `?externalId=${this.config.externalId}`;
    }

    this.siteStore.windowApis.openPersistentPopup(
      // tslint:disable-line no-floating-promises
      popupUrl,
      {
        theme: 'BARE',
        width: '0%',
        height: '100%',
        position: {
          origin: 'FIXED',
          placement: 'TOP_RIGHT',
          x: 0,
          y: 0,
        },
      },
      this.compId
    );
  };

  onIconClick = () => {
    const cartId = this.cart.cartId === EMPTY_CART_GUID ? '' : this.cart.cartId;
    const partialBi = {
      cartId: cartId || '',
      cartType: this.getCartType(),
      itemsCount: this.getTotalQuantity(this.cart.items),
      viewMode: this.siteStore.viewMode.toLowerCase(),
    };
    if (this.isOpenPopup()) {
      this.pubSubManager.publish('Minicart.Toggle', null, false);
      const eventId = this.pubSubManager.subscribe('Minicart.DidClose', () => {
        this.updateComponent({
          triggerFocus: true,
        });
        this.pubSubManager.unsubscribe('Minicart.DidClose', eventId);
      });
      //tslint:disable-next-line:no-floating-promises
      this.siteStore.biLogger.clickOnCartIconToOpenMiniCartSf({
        ...partialBi,
        isNavigateCart: false,
      });
    } else {
      const origin = 'cart-icon';
      //tslint:disable-next-line:no-floating-promises
      this.siteStore.biLogger.clickToViewCartPageSf({
        ...partialBi,
        origin,
        isNavigateCart: true,
      });
      this.cartActions.navigateToCart(origin);
    }
  };

  listenLoadedMinicartPopupAndSendCart() {
    this.pubSubManager.subscribe(
      'Minicart.LoadedWithoutData',
      () =>
        this.getData().then(cart => {
          this.pubSubManager.publish('Minicart.OnInitialData', cart.cartSummary);
          this.cart = cart.cartSummary;
        }),
      true
    );
  }

  initPopup = () => {
    this.listenLoadedMinicartPopupAndSendCart();
    this.openPopup();
  };

  public addToCart(productId: string, quantity: number, options: IAddToCartOptions = {}) {
    if (!productId) {
      return Promise.reject();
    }
    const customTextFields: IProductCustomTextAnswer[] =
      options.customTextFields &&
      options.customTextFields.map(({title, value}) => ({
        customText: {
          title,
        },
        answer: value,
      }));
    return this.cartActions
      .addToCart(
        productId,
        options.choices || {},
        quantity === undefined ? 1 : quantity,
        this.cart.cartId,
        customTextFields
      )
      .then(() => true);
  }

  public unSubscribeAll() {
    return this.pubSubManager.unsubscribeAll();
  }

  private getCartType() {
    const hasDigital = this.cart.items.some(item => item.productType === ProductType.DIGITAL);
    const hasPhysical = this.cart.items.some(item => !item.productType || item.productType === ProductType.PHYSICAL);
    if (hasDigital && hasPhysical) {
      return CartType.MIXED;
    } else if (hasDigital) {
      return CartType.DIGITAL;
    } else {
      return CartType.PHYSICAL;
    }
  }

  private getTotalQuantity(cartItems: ICartItem[] = []): number {
    return cartItems.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.quantity || 0);
    }, 0);
  }

  private getCountRelatedProps(count: number) {
    return {
      count,
      ariaLabelLink: translate(_.get(this.translations, 'sr.CART_WIDGET_BUTTON_TEXT', ''), {
        itemsCount: `${count}`,
      }),
    };
  }
}
