import {I$W, ICartIconControllerConfig} from '../types/app-types';
import {CartStore} from './cartStore';
import {
  IAppData,
  IPlatformAPI,
  IPlatformServices,
  IWixAPI,
  IWidgetController,
} from '@wix/native-components-infra/dist/src/types/types';
import {SiteStore} from '@wix/wixstores-client-core/dist/src/viewer-script/siteStore';
import {productPageController} from '@wix/wixstores-client-worker/dist/src/viewer-script/controllers/productPage';
import Experiments from '@wix/wix-experiments';
import {SENTRY_DSN, STOREFRONT_GRAPHQL_URL, CART_ICON_WIDGET_ID, PRODUCT_WIDGET_ID} from '../constants';
import {wrapExports} from './utils';

interface IContext {
  siteStore: SiteStore;
  experiments: Experiments;
  _instance?: string;

  reportError(e): any;

  wrapExportsRaven?(a): any;
}

let raven;

const context: IContext = {} as any;

const reportErrorFn = (context.reportError = e => {
  raven && raven.captureException(e);
  throw e;
});

const wrapWithRaven = (context.wrapExportsRaven = wrapExports(reportErrorFn));

function cartWidgetController(
  config: ICartIconControllerConfig,
  compId: string,
  {siteStore, experiments, reportError}: IContext,
  setProps: Function
): IWidgetController {
  let cartStore: CartStore;

  return {
    pageReady: () => {
      cartStore = new CartStore(siteStore, config, compId, experiments, setProps, reportError);
      return cartStore.setInitialState();
    },
    updateConfig: (_$w: I$W, updatedConfig: ICartIconControllerConfig) => {
      config.style = updatedConfig.style;
      config.publicData = updatedConfig.publicData;
      setProps({displayText: cartStore.getDisplayText({})});
    },
    onBeforeUnLoad: () => {
      cartStore.unSubscribeAll();
    },
    exports: () =>
      wrapWithRaven({
        addToCart(productId: string, quantity: number = 1, options: any): Promise<boolean> {
          //tslint:disable-line
          return cartStore.addToCart(productId, quantity, options);
        },
      }),
  };
}

export function initAppForPage(
  initParams: IAppData,
  apis: IPlatformAPI,
  namespaces: IWixAPI,
  platformServices: IPlatformServices
) {
  raven = platformServices.monitoring.createMonitor(SENTRY_DSN, data => {
    data.environment = 'Worker';
    return data;
  });
  const url = namespaces.location.baseUrl;
  raven.setUserContext({
    id: initParams.instanceId,
    url,
  });

  context.siteStore = new SiteStore(initParams, apis, namespaces, platformServices);
  context._instance = initParams.instance;

  return context.siteStore.httpClient
    .post(context.siteStore.resolveAbsoluteUrl(`/${STOREFRONT_GRAPHQL_URL}`), {
      query: require('!raw-loader!../graphql/getConfig.graphql'),
      source: 'WixStoresWebClient',
      operationName: 'getConfig',
    })
    .then(({data}) => {
      const experiments = (data.experiments || []).reduce((acc, e) => {
        acc[e.name] = e.value;
        return acc;
      }, {});
      context.experiments = new Experiments({experiments});
      context.siteStore.setLocale(data.clientConfig.language);
    });
}

const controllerByType = {
  [CART_ICON_WIDGET_ID]: cartWidgetController,
  [PRODUCT_WIDGET_ID]: productPageController,
};

export function createControllers(controllerConfigs) {
  function emptyCtrl() {
    return {
      pageReady: () => {
        //
      },
      exports: () => ({}),
    };
  }

  return controllerConfigs.map(({type, config, compId, setProps}) => {
    return controllerByType[type]
      ? Promise.resolve(wrapWithRaven(controllerByType[type](config, compId, context, setProps)))
      : emptyCtrl();
  });
}

export const wrappedFunctions = wrapWithRaven({
  createControllers,
  initAppForPage,
});
